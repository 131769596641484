import { useStorage } from "@vueuse/core";
import dayjs from "dayjs";

import useCheckPWA from "./useCheckPWA";
import useIsGuest from "./useIsGuest";
import useLogPWA from "./useLogPWA";

interface MyBeforeInstallPromptEvent extends Event {
	prompt: () => Promise<void>;
	userChoice: Promise<{
		outcome: "accepted" | "dismissed";
		platform: string;
	}>;
}

const usePWA = () => {
	const isGuest = useIsGuest();
	const { isDesktop, isIos } = useDevice();
	const { isPWA } = useCheckPWA();
	const { installedPWA: installedPWAMobile, isDataLoadCheck, executeInstalledPWA, executeDeletePWA } = useLogPWA();
	const deferredPrompt: Ref<MyBeforeInstallPromptEvent | null> = useState("deferredPrompt");
	const time = useStorage<string | null>("timestampPwaModal", null);
	const installedPWADesktop = useStorage<boolean>("installedPWA", false);
	const installedPWA = computed(() =>
		isDesktop ? installedPWADesktop.value : installedPWAMobile.value && isDataLoadCheck.value
	);

	const handleDesktop = () => {
		if (isPWA.value) {
			installedPWADesktop.value = true;
		} else {
			installedPWADesktop.value = deferredPrompt.value ? false : installedPWADesktop.value;
		}
	};

	const handleMobile = () => {
		setTimeout(() => {
			if (installedPWAMobile.value && !isPWA.value && deferredPrompt.value && !isIos) {
				executeDeletePWA();
			}

			if (!installedPWAMobile.value && (isPWA.value || (!deferredPrompt.value && !isIos))) {
				executeInstalledPWA();
			}
		}, 3000);
	};

	onMounted(() => {
		if (isGuest.value) {
			return;
		}

		if (isDesktop) {
			handleDesktop();
		} else {
			handleMobile();
		}
	});

	const timeHasPassed = computed(() => (time.value ? dayjs().isAfter(time.value) : false));
	const showPwaModal = computed(() => !installedPWA.value && !isGuest.value && timeHasPassed.value);
	const showPwaModalAfterGame = computed(() => !installedPWA.value && !time.value);
	const showPwaBanner = computed(() => {
		if (isPWA.value || isGuest.value) {
			return false;
		}

		if (isIos) {
			return true;
		}

		return !installedPWA.value ? deferredPrompt.value : false;
	});

	const installApp = async () => {
		if (!deferredPrompt.value) {
			return;
		}
		const beforeInstallPromptEvent = deferredPrompt.value;
		beforeInstallPromptEvent.prompt();

		const choiceResult = await beforeInstallPromptEvent.userChoice;
		deferredPrompt.value = null;

		if (isGuest.value) {
			return;
		}

		if (choiceResult.outcome === "accepted") {
			installedPWADesktop.value = true;

			if (isDesktop) {
				return;
			}

			if (!installedPWAMobile.value) {
				executeInstalledPWA();
			}
		} else {
			installedPWADesktop.value = false;

			if (isDesktop) {
				return;
			}

			if (installedPWAMobile.value) {
				executeDeletePWA();
			}
		}
	};

	return {
		installedPWA,
		showPwaBanner,
		showPwaModal,
		showPwaModalAfterGame,
		installApp
	};
};

export default usePWA;
