import useAsyncFetch from "./useAsyncFetch";
import useIsGuest from "./useIsGuest";

const useLogPWA = () => {
	const { isDesktop } = useDevice();
	const isGuest = useIsGuest();
	const { orientation } = useScreenOrientation();
	const { width, height } = useWindowSize();

	const deviceWidth = computed(() =>
		orientation.value?.startsWith("landscape") && !isDesktop ? height.value : width.value
	);

	const isDataLoadCheck = useState("pwaLogCheckloaded", () => false);

	const { data, execute: executeCheckPWA } = useAsyncFetch({
		path: "/rest/player/pwa-log/check/",
		method: "post",
		options: {
			server: false,
			immediate: false
		},
		fetchOptions: {
			body: {
				deviceWidth: deviceWidth.value || 0
			},
			onResponse: () => {
				isDataLoadCheck.value = true;
			},
			onRequestError: () => {
				isDataLoadCheck.value = true;
			},
			onResponseError: () => {
				isDataLoadCheck.value = true;
			}
		}
	});

	const { execute: executeInstalledPWA } = useAsyncFetch({
		path: "/rest/player/pwa-log/create/",
		method: "post",
		options: {
			immediate: false
		},
		fetchOptions: () => ({
			body: {
				deviceWidth: deviceWidth.value || 0
			},
			onResponse: () => {
				executeCheckPWA();
			}
		})
	});

	const { execute: executeDeletePWA } = useAsyncFetch({
		path: "/rest/player/pwa-log/delete/",
		method: "post",
		options: {
			immediate: false
		},
		fetchOptions: () => ({
			body: {
				deviceWidth: deviceWidth.value || 0,
				installedPWA: true
			},
			onResponse: () => {
				executeCheckPWA();
			}
		})
	});

	const installedPWA = computed(() => data.value?.installedPWA || false);

	onMounted(() => {
		if (!data.value && !isGuest.value && !isDesktop) {
			executeCheckPWA();
		}
	});

	watch(isGuest, (value) => {
		if (!value && !isDesktop) {
			executeCheckPWA();
		}
	});

	return {
		data,
		deviceWidth,
		isDataLoadCheck,
		installedPWA,
		executeCheckPWA,
		executeInstalledPWA,
		executeDeletePWA
	};
};

export default useLogPWA;
